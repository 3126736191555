/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../CombatJuJutsuPage/Style.css'

const CombatPage = () => {
  return (
    <>
        {/* About Combat Ju-Jutsu Section */}
        <div className='aboutCombatJuJutsu'>
            <div className='titleAndDescriptionOfCombatJuJutsu'>
                <div className='titleAboutCombatJuJutsu'>
                    <h1 className='titleCombat'>WHAT IS COMBAT JU-JUTSU?</h1>
                </div>
                <div className='descriptionAboutCombatJuJutsu'>
                    <p>Combat Ju-Jutsu is a martial art that combines sports fighting and real combat techniques for self-defense, focusing on survival in real-life situations, including facing multiple opponents.</p>
                    <p>It provides universal training by merging skills useful in both sports competition and real combat scenarios.</p>
                    <p>Initially developed for military and police use, Combat Ju-Jutsu emphasizes self-defense and is effective in situations where both striking and grappling are involved.</p>
                </div>
                <div className='combatJuJutsuPageButtonOne'>
                    <a className='combatJuJutsuButton' href='http://www.cjjif.org/eng/Combat_Ju-Jutsu_e.htm' target='_blank' rel='noreferrer'>PRINCIPLES COMBAT JU-JUTSU</a>
                </div>
            </div>
            <div className='imageOfCombatJuJutsu'>
                <img src='/images/SECTIONS1.jpg' alt='about combat ju jutsu' className='imageOfCombat'/>
            </div>
        </div>

        {/* Combat Sports Sections */}
       <div className='combatSportsSectionsContainer'>
            <div className='titleSportSections'>
                <h1 className='titleSport'>COMBAT JU-JUTSU SPORTS SECTIONS</h1>
            </div>
            <div className='combatSportsSections'>
                <div className='combatSportSection'>
                    <p>Full-Contact</p>
                </div>
                <div className='combatSportSection'>
                    <p>Close-Contact</p>
                </div>
                <div className='combatSportSection'>
                    <p>Ground-Fight</p>
                </div>
                <div className='combatSportSection'>
                    <p>Self-Defense</p>
                </div>
            </div>
       </div>

       {/* Competition Rules */}
       <div className='competitionRulesContainer'>
            <div className='titleCompetitionRulesContainer'>
                <h1 className='titleCompetitionRules'>COMPETITION RULES</h1>
            </div>
            <div className='descriptionTextDownloadButtonCompetitionRules'>
                <p className='descriptionTextDownloadButton'>Download here the official competition rules</p>
            </div>
            <div className='downloadButtons'>
                <a className='downloadButton'>Dutch</a>
                <a className='downloadButton' href='http://www.cjjif.org/eng/Rules/cjjif_championship_rules_2023_french!.pdf' target='_blank' rel='noreferrer'>French</a>
                <a className='downloadButton' href='http://www.cjjif.org/eng/Rules/cjjif-combat_ju-jutsu_rules_2023.pdf' target='_blank' rel='noreferrer'>English</a>
            </div>
       </div>
    
    </>
  )
}

export default CombatPage;
