import React from 'react'
import '../Footer/Style.css'

const Footer = () => {
  return (
    <div className='footerContainer'>
        <p className='footerName'>© 2024 CJJBF</p>
    </div>
  )
}

export default Footer;
