import React from 'react'
import '../BestuurPage/Style.css'

const BestuurPage = () => {
  return (
    <>
        <div className='bestuurPageContainer'>
          <div className='bestuurItem'>
            <img src='/images/patrick1.jpg' className='photoOfPresident' alt='pic of president' />
            <div className='descriptionOfBestuurItem'>
              <h1 className='nameOfBestuur'>Patrick Vranckx</h1>
              <h3 className='titleOfBestuur'>Head Coach of CJJBF</h3>
              <h3 className='titleOfBestuur'>President CJJ Belgian Federation</h3>
              <h3 className='gradeOfBestuur'>5th dan Combat Ju-Jutsu</h3>
              <p className='styleOfBestuur'>Style: Combat Ju-Jutsu</p>
            </div>
          </div>
        </div>

        <div className="dividerTwoBestuur"></div>
        {/* Coaches */}
        <div className='coachesSection'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/remy.jpeg' className='photoOfPresident' alt='pic of coach' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Remy Van Bokhoven</h1>
                <h3 className='titleOfBestuurTwo'>Coach</h3>
                <h3 className='gradeOfBestuurTwo'>3rd dan Combat Ju-Jutsu</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/celeste.jpeg' className='photoOfPresident' alt='pic of coach, athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Celeste Vranckx</h1>
                <h3 className='titleOfBestuurTwo'>Coach, Athlete</h3>
                <h3 className='gradeOfBestuurTwo'>1st dan Combat Ju-Jutsu</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="dividerThreeBestuur"></div>
        
        {/* Captain */}
        <div className='BestuursItemThree'>
          <div className='bestuurItem'>
          <img src='/images/joeri.jpeg' className='photoOfPresident' alt='pic of captain, athlete' />
            <div className='descriptionOfBestuurItem'>
              <h1 className='nameOfBestuurThree'>Joeri Winckelmans</h1>
              <h3 className='titleOfBestuurThree'>Athlete, Captain</h3>
              <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
            </div>
          </div>
        </div>

        <div className="dividerTwoBestuur"></div>

        {/* Athletes */}
        <div className='coachesSection'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/yonus.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Yonus Niang</h1>
                <h3 className='titleOfBestuurTwo'>Athlete</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/mats.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Mats Van Bokhoven</h1>
                <h3 className='titleOfBestuurTwo'>Athlete</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>

        <div className="dividerThreeBestuur"></div>

        {/* Athletes */}
        <div className='coachesSectionTwo'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/michael.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurThree'>Michaël Mercier</h1>
                <h3 className='titleOfBestuurThree'>Athlete</h3>
                <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/sven.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurThree'>Sven De Vos</h1>
                <h3 className='titleOfBestuurThree'>Athlete</h3>
                <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>

        <div className="dividerTwoBestuur"></div>

        {/* Athletes */}
        <div className='coachesSection'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/matijs.jpg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Matijs Vandezande</h1>
                <h3 className='titleOfBestuurTwo'>Athlete</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/steven.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Steven Rylant</h1>
                <h3 className='titleOfBestuurTwo'>Athlete</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>

        <div className="dividerThreeBestuur"></div>
        {/* Athletes */}
        <div className='coachesSectionTwo'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/gabriella.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurThree'>Gabriella Gorlewska</h1>
                <h3 className='titleOfBestuurThree'>Athlete</h3>
                <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/aslan.jpg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurThree'>Aslan Van Oppens</h1>
                <h3 className='titleOfBestuurThree'>Athlete</h3>
                <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>

        <div className="dividerTwoBestuur"></div>

        {/* Athletes */}
        <div className='coachesSection'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/marcell.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Marcell Gorlewski</h1>
                <h3 className='titleOfBestuurTwo'>Athlete</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/yunah.jpg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurTwo'>Yunah Aguilar Milner</h1>
                <h3 className='titleOfBestuurTwo'>Athlete</h3>
                <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>

        <div className="dividerThreeBestuur"></div>
        {/* Athletes */}
        <div className='coachesSectionTwo'>
          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/alan.jpg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurThree'>Alan Rogala</h1>
                <h3 className='titleOfBestuurThree'>Athlete</h3>
                <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>

          <div className='BestuursItemTwo'>
            <div className='bestuurItem'>
            <img src='/images/roan.jpeg' className='photoOfPresident' alt='pic of athlete' />
              <div className='descriptionOfBestuurItem'>
                <h1 className='nameOfBestuurThree'>Roan Mercier</h1>
                <h3 className='titleOfBestuurThree'>Athlete</h3>
                <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default BestuurPage;